import { Component, OnInit, ViewEncapsulation, ComponentFactoryResolver, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription, forkJoin, of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';
import * as html2pdf from 'html2pdf.js';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AccountService, ConfirmDialogComponent, getQuestions, InformationDialogComponent, LanguageService, ProgressSpinnerService, QuestionAnswerGetResponse, QuestionAnswerService, QuestionComponent, QuestionGroupResponse, QuestionItem, QuestionListDirective, SurveyStateService } from '../../public-api';
import { PdfGenerationService2 } from '../../services/pdf-generation.service';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-summary-survey-content',
  templateUrl: './survey-summary-content.component.html',
  styleUrls: ['./survey-summary-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SurveySummaryContentComponent implements OnInit, AfterViewInit {
  @ViewChildren(QuestionListDirective) questionList: QueryList<QuestionListDirective>;
  currentPageQuestions: QuestionItem[];
  questionGroups: QuestionGroupResponse[];
  sectionTitleHeaders: string[] = [];

  private answerSavedSubscription: Subscription;
  private questionGroupLoadedSubscription: Subscription;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private messageService: MessageService,
    private surveyStateService: SurveyStateService,
    private progressSpinnerService: ProgressSpinnerService,
    private questionAnswerService: QuestionAnswerService,
    private languageService: LanguageService,
    // private pdfGenerationService: PdfGenerationService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private accountService: AccountService,
    private pdfGenerationService2: PdfGenerationService2
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.load();
  }

  private load() {
    const url = window.location.href;
    const token = url.split('/').pop().split('?')[0];
    var user = this.accountService.getUser();
    var selectedSurvey = user.surveys.find(s => s.surveyToken === token);

    this.accountService.setCurrentSurveyInstance(selectedSurvey.surveyInstanceId);

    this.progressSpinnerService.show();

    this.questionGroups = this.surveyStateService.getQuestionGroupsInOrder();

    var questionGroupsRequest = this.questionGroups.length > 0 ? of(this.questionGroups) : this.surveyStateService.initSurveyCommand2(selectedSurvey.surveyInstanceId, this.languageService.language);

    questionGroupsRequest.pipe(
      tap(data => this.questionGroups = this.surveyStateService.getQuestionGroupsInOrder()),
      concatMap(() => {
        const tasks$ = [];
        this.questionGroups.forEach(qg => {
          tasks$.push(this.questionAnswerService
            .apiQuestionAnswerGet$Json({
              questionGroupId: qg.id,
              language: this.languageService.language,
              surveyInstanceId: selectedSurvey.surveyInstanceId
            }))
        });
        return forkJoin(tasks$);
      })
    ).subscribe((responses: QuestionAnswerGetResponse[]) => {
      this.loadComponent(responses);
      this.progressSpinnerService.hide()
    }, _ => {
      this.progressSpinnerService.hide()
    });
  }

  loadComponent(questionsGroups: QuestionAnswerGetResponse[]) {
    // setTimeout(() => {
      try {

        var surveySections = this.surveyStateService.GetSurveySections();

        this.questionList.forEach((question, index) => {
          const viewContainerRef = question.viewContainerRef;
          // viewContainerRef.clear();

          var questionsInGroup = getQuestions(questionsGroups[index]);
          this.sectionTitleHeaders.push(surveySections[index].text);
          questionsInGroup.forEach(question => {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(question.component);
            const componentRef = viewContainerRef.createComponent(componentFactory);
            (componentRef.instance as QuestionComponent).questionData = question.questionData;
            (componentRef.instance as QuestionComponent).questionAnswerInstanceList = question.questionAnswerInstanceList;
            (componentRef.instance as QuestionComponent).messageService = this.messageService;
            (componentRef.instance as QuestionComponent).readonly = true;
            (componentRef.instance as QuestionComponent).surveyStateService = this.surveyStateService;
          });
        });
      } catch (error) {
        // Handle the error
        console.error('An error occurred:', error);
      }
    // }, 5000);
  }

  submitClicked() {
    this.dialog.open(ConfirmDialogComponent, {
      disableClose: false,
      data: {
        message: this.translate.instant('Are you sure you want to submit questionnaire?')
      }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.surveyStateService.submitSurveyCommand();
      }
    });
  }

  generatePdf() {
    var elements = document.querySelectorAll<HTMLElement>('.pdf');

    var array = Array.from(elements);
    // array = [array[1]];
    var content = new ElementRef(array); // this.combineElements(array);

    var dialogRef = this.dialog.open(InformationDialogComponent, {
      disableClose: false,
      data: {
        message: this.translate.instant('PDF is being generated. This may take a few seconds')
      }
    });

    setTimeout(() =>{
      // this.progressSpinnerService.show();
      this.pdfGenerationService2.exportSlides(array, 'summary',  (i, onlyLegend) => { })
        .subscribe(_ => {
          // this.progressSpinnerService.hide();
        }, error => {
          console.log(error);
          // this.progressSpinnerService.hide();

          var message = this.translate.instant('An error occurred while generating the PDF. Please try again later.');
          this.messageService.add({ severity: 'error', summary: 'Error', detail: message, sticky: false });
          this.dialog.open(InformationDialogComponent, {
          disableClose: false,
          data: {
            message: this.translate.instant('An error occurred while generating the PDF. Please try again later.')
          }
          });
      });
    })
    // setTimeout(() => this.downloadPDF(array, options), 100);
  }

  private downloadPDF(elements: HTMLElement[], options: any) {
    let worker = html2pdf()
      .set(options)
      .from(elements[0]);

    if (elements.length > 1) {
      worker = worker.toPdf(); // worker is now a jsPDF instance

      elements.slice(1).forEach((element) => {
        worker = worker
          .get('pdf')
          .then((pdf) => {
            pdf.addPage();
          })
          .from(element)
          .toContainer()
          .toCanvas()
          .toPdf();
      });
    }

    worker.save();
  }

  // private combineElements(elements: HTMLElement[]): HTMLElement {
  //   const combinedElement = document.createElement('div');

  //   elements.forEach(element => {
  //     combinedElement.appendChild(element.cloneNode(true));
  //   });

  //   return combinedElement;
  // }

  private combineElements(elements: HTMLElement[]): ElementRef<any> {
    const combinedElement = document.createElement('div');

    elements.forEach(element => {
      combinedElement.appendChild(element.cloneNode(true));
    });

    return new ElementRef(combinedElement);
  }
}

